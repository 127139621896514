import {galleryConfig} from './galleries';

const metadata = {
    siteTitle: 'Joanna Jakubik Fotografie',
    siteTitleAlt: 'Joanna Jakubik Fotografie',
    shortSiteTitle: 'Joanna Jakubik',

    siteDescription: 'Joanna Jakubik ist eine in Wien arbeitende Fotografin und kreative Unternehmerin, die es sich zur Aufgabe gemacht hat, Menschen zu inspirieren und deren Leben aus einem anderen Blickwinkel zu betrachten.',

    siteUrl: 'https://www.joannajakubik.com',
    siteLogo: './content/images/icon.png',

    siteOwner: 'Joanna Jakubik',
    siteOwnerEmail: 'hello@joannajakubik.com',

    siteLanguage: 'de-DE',
    author: 'Joanna Jakubik <hello@joannajakubik.com',
    titleTemplate: '%s | Joanna Jakubik Fotografie',
    defaultKeywords: 'Fotografie, Portrait, Fotostudio, Fotokunst',
};

export const website = {
    ...metadata,
    googleAnalyticsID: 'G-Q8CD3LN0Z7',

    // Manifest
    themeColor: '#cfcfcf',
    backgroundColor: 'grey',

};

const galleryItems = galleryConfig.galleries.filter(gallery => gallery.inNavbar)
    .map(g => (
        {
            link: `/${g.galleryName.toLowerCase()}/`,
            title: g.linkText,
            exact: true,
        }
    ));

export const NavigationItems = [
    {
        link: '/',
        title: 'Home',
        exact: true,
    },
    ...galleryItems,
    {
        link: '/kontakt/',
        title: 'Kontakt',
        exact: true,
    },
    {
        link: '/clients/',
        title: 'Kundengallerien',
        exact: true,
    },
];
