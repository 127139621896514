// Import React from 'react';
import {type PaletteMode} from '@mui/material';
import {grey} from '@mui/material/colors';

// Declare module "@mui/material/styles" {
//     interface BreakpointOverrides {
//         zero: true;
//         xs: false;
//         sm: true;
//         md: false;
//         lg: true;
//         xl: false;
//     }
// }

export const getPaletteDesignTokens = (mode: PaletteMode) => {
    return ({
        typography: {
            fontFamily: 'Cormorant',
        },
        palette: {
            mode,
            ...(mode === 'light' ? {
                primary: {
                    main: '#544037',
                },
                secondary: {
                    main: '#544037',
                },
                background: {
                    default: '#f5f0e8',
                },
                text: {
                    primary: '#544037',
                    secondary: '#544037',
                }
            } : {
                primary: {
                    main: grey[300],
                },
                secondary: {
                    main: grey[200],
                },
                background: {
                    default: grey[900],
                },
            }),
        },
    });
};
