import React, {forwardRef} from 'react';
import MuiLink from '@mui/material/Link';
import {Link as GatsbyLink} from 'gatsby';

type LinkProps = {
    color: string;
    to: string;
    children: string;
    style?: object;
};

export const Link = forwardRef((props: LinkProps, ref) => (<MuiLink component={GatsbyLink} {...props} {...ref} />));
