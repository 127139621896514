import React from 'react';
import {Button} from '@mui/material';
import {NavigationLink} from './navigation-link';

type ComponentProps = {
    linkText: string;
    linkTo: string;
    navigateTo: (path: string) => void;
};

export const DesktopItem = ({navigateTo, linkText, linkTo}: ComponentProps) => (
    <Button onClick={() => {
        navigateTo(linkTo);
    }} value={linkTo}>
        <NavigationLink linkText={linkText} linkTo={linkTo}/>
    </Button>
);
