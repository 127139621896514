import React from 'react';
import {Box, Typography} from '@mui/material';
import {Link} from '../link';

export type NavigationLinkType = {
    linkText: string;
    linkTo: string;
};

export function NavigationLink(props: NavigationLinkType) {
    const {linkTo, linkText} = props;
    return (
        <Box p={0.5}>
            <Typography variant={'button'} noWrap={true}>
                <Link color={'primary'} to={linkTo} style={{textDecoration: 'none'}}>
                    {linkText}
                </Link>
            </Typography>
        </Box>
    );
}
