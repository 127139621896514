import React from 'react';
import {Grid, Slide} from '@mui/material';
import {SnackbarProvider} from 'notistack';
import {MuiMenu} from './Nav/mui-menu';
import {Footer} from './footer';

type Props = {
    window?: () => Window;
    children: React.ReactElement;
};

export const AppLayout = (props: Props) => (
    <>
        <SnackbarProvider maxSnack={3} anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            TransitionComponent={Slide} preventDuplicate={true} dense>
            <Grid>
                <MuiMenu/>
                <Grid paddingY={6}>
                    <Grid component='main'>
                        {props.children}
                    </Grid>
                </Grid>
                <Footer/>
            </Grid>
        </SnackbarProvider>
    </>
);

