import * as React from 'react';
import {
  Components,
  createTheme,
  PaletteMode,
  responsiveFontSizes, ThemeOptions,
  useMediaQuery,
} from '@mui/material';
import { getPaletteDesignTokens } from '../../src/styles/theme';

export const ColorModeContext = React.createContext({
  mode: 'light',
  toggleColorMode() {
  },
});

function useAppTheme() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const [mode, setMode] = React.useState<PaletteMode>(
    prefersDarkMode ? 'dark' : 'light',
  );

  const colorMode = React.useMemo(() => ({
    mode,
    toggleColorMode() {
      setMode(previousMode => (previousMode === 'light' ? 'dark' : 'light'));
    },
  }), [mode]);

  /* Override mui Styles */
  const components: Components = {
    MuiFormHelperText:{
      styleOverrides: {
        root: {
          fontSize: '1rem'
        }
      }
    }
  };

  // Update the theme only if the mode changes
  const appTheme = React.useMemo(() => {
    const themeProps: ThemeOptions = {
      components,
      ...getPaletteDesignTokens(mode),
    };

    const theme = createTheme(themeProps);
    return responsiveFontSizes(theme);
  }, [mode]);

  return { colorMode, appTheme };
}

export { useAppTheme };
