import React from 'react';
import {AppBar, Box, Button, IconButton, Menu, Toolbar} from '@mui/material';
import {navigate} from 'gatsby';
import {Menu as MenuIcon} from '@mui/icons-material';
import slugify from 'slugify';
import {BreadcrumbJsonLd} from 'gatsby-plugin-next-seo';
import {LayoutWithContainer} from '../layout-with-container';
import {Logo} from '../logo';
import {galleryConfig} from '../../config';
import {HideOnScroll} from '../../utils/hide-on-scroll';
import {type IGallery} from '../../config/galleries';
import {MobileNavItem} from './mobile-item';
import {DesktopItem} from './desktop-item';

type navigationItemProps = {
    linkText: string;
    linkPath: string;
};

function getPath(galleryName: string) {
    return `/${slugify(galleryName, {lower: true, trim: true})}/`;
}

export const MuiMenu = (): JSX.Element => {
    const [anchorElementNav, setAnchorElementNav] = React.useState<undefined | HTMLElement>(undefined);
    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElementNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElementNav(undefined);
    };

    const navigateTo = (path: string) => {
        handleCloseNavMenu();
        navigate(path);
    };

    const navigationItems: Array<navigationItemProps | undefined> = [
        {
            linkPath: '/',
            linkText: 'Home',
        },
        ...galleryConfig.galleries.map((gallery: IGallery) => {
            if (gallery.inNavbar) {
                return {
                    linkText: gallery.linkText,
                    linkPath: getPath(gallery.galleryName),
                };
            }

            return undefined;
        }),
        {
            linkPath: '/contact/',
            linkText: 'Kontakt',
        },
        {
            linkPath: '/clients/',
            linkText: 'Kundengallerien',
        },

    ];

    return (
        <>
            <BreadcrumbJsonLd itemListElements={navigationItems.map((navItem, index) => ({
                position: index + 1,
                name: navItem!.linkText,
                item: navItem!.linkPath,
            }))}/>
            <HideOnScroll>
                <AppBar color={'default'} style={{zIndex: 999}}>
                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <LayoutWithContainer>
                            <Toolbar
                                sx={{justifyContent: 'space-between', height: 6}}
                                variant='dense'
                            >
                                <Button onClick={() => {
                                    navigateTo('/');
                                }}>
                                    <Logo/>
                                </Button>
                            </Toolbar>
                        </LayoutWithContainer>
                        <Box sx={{flexGrow: 1, display: {sm: 'flex', md: 'none'}}}>
                            <IconButton
                                size='large'
                                aria-controls='menu-appbar'
                                aria-haspopup='true'
                                onClick={handleOpenNavMenu}
                                color='inherit'
                                name={'menu'}
                                aria-label={'main-menu'}
                            >
                                <MenuIcon/>
                            </IconButton>
                            <Menu
                                id='menu-appbar'
                                anchorEl={anchorElementNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElementNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: {sm: 'block', md: 'none'},
                                }}
                            >
                                {navigationItems.map(navItem => {
                                    if (navItem) {
                                        return (
                                            <MobileNavItem key={`mobNav_${navItem.linkText.toLowerCase()}`}
                                                linkText={navItem.linkText} linkTo={navItem.linkPath}
                                                navigateTo={navigateTo}
                                            />
                                        );
                                    }

                                    return (
                                        <></>
                                    );
                                })}
                            </Menu>
                        </Box>
                        <Box sx={{flexGrow: 1, display: {xs: 'none', sm: 'none', md: 'flex'}}}>
                            {navigationItems.map(navItem => {
                                if (navItem) {
                                    return (
                                        <DesktopItem key={`desktopItem_${navItem.linkText.toLowerCase()}`}
                                            linkText={navItem.linkText} linkTo={navItem.linkPath}
                                            navigateTo={navigateTo}/>
                                    );
                                }

                                return (
                                    <></>
                                );
                            })}
                        </Box>
                    </Box>
                </AppBar>
            </HideOnScroll>
        </>
    );
};
