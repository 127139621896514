import React from 'react';
import {Box, Paper, Typography} from '@mui/material';
import {Link as GatsbyLink} from 'gatsby';
import packageProps from '../../package.json';
import {website} from '../config/website';

export const Footer = () => (
    <>
        <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={2}>
            <Box component={'span'} display={'flex'} justifyContent={'center'}>
                <Typography>
                    ©{`${new Date().getFullYear()} ${website.siteOwner}`} | E-mail: {`${website.siteOwnerEmail}`} |&nbsp;
                    <GatsbyLink to={'/terms'}>AGB's</GatsbyLink>&nbsp;| v:{packageProps.version}
                </Typography>
            </Box>
        </Paper>
    </>
);
