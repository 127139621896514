import React, {Fragment} from 'react';
import {CssBaseline, ThemeProvider} from '@mui/material';
import {AppLayout} from '../../src/components/app-layout';
import {ColorModeContext, useAppTheme} from './useAppTheme';

export default function TopLayout(props: any) {
    const {colorMode, appTheme} = useAppTheme();

    return (
        <Fragment>
            <>
                <meta name='viewport' content='minimum-scale=1, initial-scale=1, width=device-width' />
                <ColorModeContext.Provider value={colorMode}>
                    <ThemeProvider theme={appTheme}>
                        <CssBaseline/>
                        <AppLayout>
                            {props.children}
                        </AppLayout>
                    </ThemeProvider>
                </ColorModeContext.Provider>
            </>
        </Fragment>
    );
}
