import React from 'react';
import {MenuItem} from '@mui/material';
import {NavigationLink} from './navigation-link';

type ComponentProps = {
    linkText: string;
    linkTo: string;
    navigateTo: (path: string) => void;
};

export const MobileNavItem = ({navigateTo, linkTo, linkText}: ComponentProps) => {
    const activeSite = (pageLink: string): boolean => {
        let location = '';
        if (typeof window !== 'undefined') {
            location = window.location.pathname;
        }

        return location === pageLink;
    };

    return (
        <MenuItem onClick={() => {
            navigateTo(linkTo);
        }} selected={activeSite(linkTo)}>
            <NavigationLink linkText={linkText} linkTo={linkTo}/>
        </MenuItem>
    );
};
