export type IGallery = {
    galleryName: string;
    galleryDescription?: string;
    linkText: string;
    onLandingPage: boolean;
    inNavbar: boolean;
};

const galleries: IGallery[] = [
    {
        galleryName: 'Portrait',
        galleryDescription: '',
        linkText: 'Portrait',
        onLandingPage: true,
        inNavbar: true,
    },
    {
        galleryName: 'Yoga',
        galleryDescription: '',
        linkText: 'Yoga',
        onLandingPage: true,
        inNavbar: true,
    },
    {
        galleryName: 'Places',
        galleryDescription: '',
        linkText: 'Places',
        onLandingPage: true,
        inNavbar: true,
    },
    {
        galleryName: 'Completefree',
        galleryDescription: '',
        linkText: 'Komplett Frei',
        onLandingPage: false,
        inNavbar: true,
    },
];

export type IGalleryConfig = {
    galleryMainPath: string;
    galleryTitleImages: string;
    galleries: IGallery[];
};

export const galleryConfig: IGalleryConfig = {
    galleryMainPath: 'src/content/galleries',
    galleryTitleImages: 'src/content/images/gallerieTitleImages',
    galleries: [...galleries],
};

