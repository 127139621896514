import React, {type ReactElement} from 'react';
import {Slide, useScrollTrigger} from '@mui/material';

export function HideOnScroll(props: {children: ReactElement}) {
    const {children} = props;
    const trigger = useScrollTrigger();

    return (
        <Slide appear={false} direction='down' in={!trigger}>
            {children}
        </Slide>
    );
}
